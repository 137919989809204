<template>
  <div
    v-if="dashboardEnabled"
    class="vue-dashboard-row"
    :style="rowstyles"
  >
    <vue-dashboard-item
      v-for="(dashboardItem, id) in dashboardItems"
      :key="id"
      :dashboard-id="dashboardId"
      :item-config="dashboardItem"
    />
  </div>
</template>

<script>

import VueDashboardItem from './vue-dashboard-item.vue'

export default {
  name: 'vue-dashboard-row',
  components: {
    VueDashboardItem
  },
  props: {
    dashboardId: {
      type: String,
      required: true
    },
    dashboardRow: {
      type: Object,
      required: true,
      default: () => ({
        enable: true,
        items: [{
          enable: true,
          type: 'heading',
          content: 'No charts are defined'
        }]
      })
    }
  },
  computed: {
    rowstyles: function() {
      return !Object.prototype.hasOwnProperty.call(this.dashboardRow, 'styles') ? '' : Object.entries(this.dashboardRow.styles).map(([key, value]) => `${key}: ${value};`).join('; ')
    },
    dashboardEnabled: function() {
      return Object.prototype.hasOwnProperty.call(this.dashboardRow, 'enable') ? this.dashboardRow.enable : true
    },
    dashboardItems: function() {
      return Object.prototype.hasOwnProperty.call(this.dashboardRow, 'items') ? this.dashboardRow.items : []
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue-dashboard-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
  }
</style>
