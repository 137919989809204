import component from './components/vue-dashboard-page.vue'
import DashboardStore from './store'

export function install(Vue, options) {
  if (install.installed) return
  if (!options.store) {
    throw new Error('Please initalize the Vue Dashboard Component with a vuex store.')
  }
  install.installed = true
  options.store.registerModule('vuedashboard', DashboardStore)
  Vue.component('vue-dashboard-page', component)
}

const plugin = {
  install
}

let GlobalVue = null
if (typeof window !== 'undefined') {
  GlobalVue = window.Vue
}
else if (typeof global !== 'undefined') {
  GlobalVue = global.Vue
}
if (GlobalVue) {
  GlobalVue.use(plugin)
}

export default { install, component }
