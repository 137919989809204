<template>
  <div class="vuejs-echarts-report-page-wrapper">
    <loading-spinner v-if="loading">
      <div slot="title">
        Loading Dashboard configuration
      </div>
    </loading-spinner>

    <section
      v-if="!loading"
      class="reports__filters"
    >
      <section class="filters-headline">
        <h1 v-if="title">
          {{ title }}
        </h1>
        <p
          v-if="description"
          class="filters-headline__description"
        >
          {{ description }}
        </p>
      </section>

      <section class="filters-form">
        <section class="form-group form-group--date">
          <label
            for="report-range"
            class="form-group__label"
          >
            Date Range:
          </label>

          <select
            v-model="daterange"
            class="form-group__input form-control form-group__input--select"
          >
            <option
              v-for="(label, id) in dateOptions"
              :key="id"
              :value="label"
            >
              {{ label }}
            </option>
          </select>
        </section>

        <section
          v-for="(filter, id) in additionalOptions"
          :key="id"
          class="form-additional-options form-group"
        >
          <component
            :is="filterWidget(filter)"
            :config="filter"
            :value="additionalFilterValue(filter)"
            @input="(val) => updateFilterValue(filter, val)"
          />
        </section>
      </section>

      <section
        v-if="contentAbove"
        class="content-above"
      >
        <div v-html="contentAbove" />
      </section>

      <section class="reports">
        <div
          v-for="(dashboardRow, id) in dashboardContent"
          :key="id"
          class="reports-row"
        >
          <vue-dashboard-row
            :dashboard-row="dashboardRow"
            :dashboard-id="dashboardId"
          />
        </div>
      </section>
      <section
        v-if="contentBelow"
        class="content-below"
      >
        <div v-html="contentBelow" />
      </section>
    </section>
  </div>
</template>

<script>

import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
// eslint-disable-next-line camelcase
import dayjs_plugin_utc from 'dayjs/plugin/utc'
// eslint-disable-next-line camelcase
import dayjs_plugin_timezone from 'dayjs/plugin/timezone'
import VueDashboardFilterWidgetSelect from './widgets/vue-dashboard-filter-widget-select.vue'
import VueDashboardFilterWidgetSwoggle from './widgets/vue-dashboard-filter-widget-swoggle.vue'

import LoadingSpinner from '@aspedia/loading-spinner'
import defaultDashboardDates from './dates'

import VueDashboardRow from './vue-dashboard-row.vue'

dayjs.extend(dayjs_plugin_timezone)
dayjs.extend(dayjs_plugin_utc)

export default {
  name: 'vue-dashboard-page',
  components: {
    LoadingSpinner,
    VueDashboardFilterWidgetSelect,
    VueDashboardFilterWidgetSwoggle,
    VueDashboardRow
  },
  props: {
    siteid: {
      type: String,
      required: true
    },
    auth: {
      type: [Function, String],
      required: true
    },
    endpoint: {
      type: String,
      required: false,
      default: 'https://biin.api.aspedia.io'
    },
    siteUrl: {
      type: String,
      required: true
    },
    dashboardConfig: {
      type: [String, Object],
      required: false,
      default: () => ({
        title: 'Example Dashboard',
        description: 'This is an example Dashboard',
        dashboardContent: [
          [{
            type: 'heading',
            content: 'No charts are defined'
          }]
        ]
      })
    }
  },
  data: function() {
    const data = {
      loading: true,
      daterange: 'this week',
      dashboardId: uuidv4(),
      aboveContentHandler: null,
      belowContentHandler: null,
      contentAbove: null,
      contentBelow: null,
      additionalOptionsValues: {}
    }
    return data
  },
  computed: {
    handlebars: function() {
      return this.$store.getters['vuedashboard/handlebars'](this.dashboardId)
    },
    title: function() {
      return Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'title') ? this.dashboardConfig.title : null
    },
    description: function() {
      return Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'description') ? this.dashboardConfig.description : null
    },
    dashboardContent: function() {
      return this.dashboardConfig.dashboardContent
    },
    dateOptions: function() {
      return Object.keys(this.dates)
    },
    additionalFilterValue: function() {
      return (filter) => {
        return this[filter.value]
      }
    },
    dates: function() {
      return Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'dates') ? this.dashboardConfig.dates : defaultDashboardDates
    },
    defaultDate: function() {
      let candidateDate = Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'defaultDate') ? this.dashboardConfig.defaultDate : 'this week'
      if (!Object.prototype.hasOwnProperty.call(this.dates, candidateDate)) {
        candidateDate = Object.keys(this.dates)[0]
      }
      return candidateDate
    },
    requestVars: function() {
      return this.$store.getters['vuedashboard/requestVars'](this.dashboardId)
    },
    siteVariables: function() {
      return this.$store.getters['vuedashboard/siteVariables'](this.dashboardId)
    },
    filterWidget: function() {
      return (filter) => {
        switch (filter.type) {
        case 'swoggle':
          return 'vue-dashboard-filter-widget-swoggle'
        case 'select':
        default:
          return 'vue-dashboard-filter-widget-select'
        }
      }
    },
    trigger: function() {
      return this.$store.state.vuedashboard.trigger
    }
  },
  watch: {
    trigger: function() {
      this.updateContent()
    },
    daterange: function(newValue) {
      this.$store.commit('vuedashboard/SET_DATES', { dashboardID: this.dashboardId, ...this.dates[this.daterange] })
    }
  },
  unmounted: function() {
    this.$store.commit('vuedashboard/RESET', this.dashboardID)
  },
  mounted: function() {
    this.loading = true
    return this.$store.dispatch('vuedashboard/init', { dashboardID: this.dashboardId, siteid: this.siteid, auth: this.auth, endpoint: this.endpoint, siteURL: this.siteUrl })
      .then(_ => {
        if (Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'content') && Object.prototype.hasOwnProperty.call(this.dashboardConfig.content, 'above')) {
          this.aboveContentHandler = this.handlebars.compile(this.dashboardConfig.content.above)
        }
        if (Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'content') && Object.prototype.hasOwnProperty.call(this.dashboardConfig.content, 'below')) {
          this.belowContentHandler = this.handlebars.compile(this.dashboardConfig.content.below)
        }

        this.$store.commit('vuedashboard/SET_DATES', { dashboardID: this.dashboardId, ...this.dates[this.daterange] })
        this.$set(this, 'daterange', this.defaultDate)

        this.additionalOptions = Object.prototype.hasOwnProperty.call(this.dashboardConfig, 'additionalOptions') ? this.dashboardConfig.additionalOptions : []
        if (typeof this.additionalOptions === 'string') {
          const handlebars = this.$store.getters['vuedashboard/handlebars'](this.dashboardId)
          const template = handlebars.compile(this.additionalOptions)
          let content = template()
          this.additionalOptions = JSON.parse(content)
        }
        this.additionalOptions.forEach(filter => {
          this.$set(this.additionalOptionsValues, filter.value, filter.default)
          this.$store.commit('vuedashboard/SET_REQUEST_VARS', {
            dashboardID: this.dashboardId,
            [filter.value]: filter.default,
            [`${filter.value}__LABEL`]: this.getFilterValue(filter, filter.default)
          })
        })
        this.updateContent()
        this.loading = false
      })
  },
  methods: {
    updateContent: function() {
      if (this.aboveContentHandler) {
        this.contentAbove = this.aboveContentHandler({ ...this.requestVars, ...this.siteVariables })
      }
      if (this.belowContentHandler) {
        this.contentBelow = this.belowContentHandler({ ...this.requestVars, ...this.siteVariables })
      }
    },
    getFilterValue: function(filter, value) {
      let options = {}
      switch (filter.type) {
      case 'swoggle':
        return value

      case 'select':
        options = filter.options
        if (filter.depends) {
          const dependantValue = this.additionalOptionsValues[filter.depends]
          if (Object.prototype.hasOwnProperty.call(filter.options, dependantValue)) {
            options = filter.options[dependantValue]
          }
        }
        return options[value]
      }
    },
    sanitizeUrl: function(string) {
      return string.replace(/\/$/, '')
    },
    updateFilterValue: function(filter, value) {
      this.$set(this.additionalOptionsValues, filter.value, value)
      const data = {
        dashboardID: this.dashboardId,
        [filter.value]: value,
        [`${filter.value}__LABEL`]: this.getFilterValue(filter, value)
      }
      this.$store.commit('vuedashboard/SET_REQUEST_VARS', data)
      this.$store.commit('vuedashboard/SET_DATES', {
        dashboardID: this.dashboardId,
        ...this.dates[this.daterange]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .vuejs-echarts-report-page-wrapper {

    .report-date-range {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 2.2rem;
      font-weight: bold;
      padding: 0.8em 0;
    }
  }

  .reports__filters {

    .filters-headline {
      margin-bottom: 1em;

      &__description {
        color: rgba(#000, 0.7);
      }
    }

    .filters-form {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.5em;

      .form-group {
        display: flex;
        flex-direction: row;
        margin-right: 1em;
        margin-top: 0.25em;

        ::v-deep &__label {
          display: flex;
          align-items: center;
          opacity: 0.7;
          font-weight: 400;
          min-width: fit-content;
          padding: 0 0.8rem 0 0;
        }

        &__input {
          ::v-deep input {
            font-size: 16px;
            cursor: pointer;
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding: 8px 20px 8px 8px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 4px;
            background: transparent;
            border: 1px solid #e0e0e0;
            box-shadow: 0 0.1em 0.3em rgba(0,0,0,.05);
            outline: 0;
          }
        }

        ::v-deep &__input--select.form-control {
          font-size: 14px;
          display: block;
          width: 100%;
          box-sizing: border-box;
          padding: 8px 20px 8px 8px!important;
          border-radius: 4px;
          background: transparent;
          border: 1px solid #e0e0e0;
          box-shadow: 0 0.1em 0.3em rgba(0,0,0,.05);
          outline: 0;
          cursor: pointer;

          & > option[disabled] {
            opacity: 0.7;
          }
        }
      }
    }
  }

</style>
