<template>
  <div class="report-dashboard-item-empty" />
</template>

<script>
export default {
  name: 'vue-dashboard-item-empty',
  props: {
    itemConfig: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
