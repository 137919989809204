export default {
  computed: {
    trigger: function() {
      return this.$store.state.vuedashboard.trigger
    }
  },
  watch: {
    trigger: {
      immediate: true,
      handler: function() {
        this.update()
      }
    }
  },
  methods: {
    update: function() {
      return this.$store.dispatch('vuedashboard/request', { dashboardID: this.dashboardId, query: this.itemConfig.query })
        .then(result => {
          try {
            if (this.postQueryHandler && result && Object.keys(result).length) {
              const processedResult = this.postQueryHandler({ ...this.requestVars, ...result })
              return JSON.parse(processedResult)
            }
            return result
          }
          catch (err) {
            console.error(`Error parsing result: ${err.message}`)
            return result
          }
        })
        .then(resultData => {
          this.$set(this, 'resultData', resultData)
        })
    }
  }
}
