import Papa from 'papaparse'
import async from 'async'
import GraphQLClient from '@aspedia/graphql_client'
import SiteHandlebars from './handlebars'

const requestHandler = ({ auth, handlebars, siteid, serviceURL, dashboardID, state }) => (query, callback) => {
  const handler = handlebars.compile(query)
  return auth()
    .then(jwt => fetch(`${serviceURL}/influx/query`, {
      method: 'POST',
      headers: {
        'X-Site-ID': siteid,
        'Authorization': `Bearer ${jwt}`,
        'Content-Type': 'application/vnd.flux'
      },
      body: handler(state.requestVars[dashboardID])
    }))
    .then(resp => {
      if (resp.ok) {
        return resp.text()
      }
      else {
        resp.text()
          .then(err => {
            throw new Error('Error Retrieving data: ' + err)
          })
      }
    })
    .then(data => {
      // eslint-disable-next-line no-control-regex
      data = data.trim().split(/\r?\n\r?\n/).filter(x => x)
      let CSVData = data.map(resultSet => Papa.parse(resultSet.trim(), { header: true }))
        .filter(items => items.data.length)
        .map(dataset => dataset.data)

      // const dropColumns = ['_start', '_stop', '_time', 'table', 'result', '']

      return CSVData.reduce((resultDatasets, dataset) => {
        return {
          ...resultDatasets,
          [dataset[0].result]: dataset
          // [dataset[0].result]: dataset.map(row => Object.entries(row).reduce((zz, [field, value]) => dropColumns.includes(field) ? zz : { ...zz, [field]: value }, {}))
        }
      }, {})
    })
    .then(result => {
      callback(null, result)
    })
    .catch(err => {
      callback(err)
    })
}

const state = () => ({
  dashboards: {},
  requestQueue: {},
  requestVars: {},
  siteVariables: {},
  handlebars: {},
  trigger: 0
})

const mutations = {
  RESET: function(state, payload) {
    if (payload === undefined) {
      state.dashboards = {}
      state.requestQueue = {}
      state.requestVars = {}
      state.siteVariables = {}
      state.handlebars = {}
      state.trigger = 0
    }
    else {
      delete state.dashboards[payload.daboardID]
      delete state.requestQueue[payload.daboardID]
      delete state.requestVars[payload.daboardID]
      delete state.siteVariables[payload.dashboardID]
      delete state.handlebars[payload.dashboardID]
    }
  },
  SET_DASHBOARD_SETTINGS: function(state, payload) {
    if (!Object.prototype.hasOwnProperty.call(payload, 'dashboardID')) {
      throw new Error('Missing dashboard ID')
    }
    if (Object.prototype.hasOwnProperty.call(state.dashboards, payload.dashboardID)) {
      throw new Error('Attempt to re-initialized an existing dashboard')
    }
    state.dashboards[payload.dashboardID] = {
      siteid: payload.siteid,
      auth: (typeof payload !== 'function') ? () => Promise.resolve(payload.auth) : payload.auth,
      serviceURL: payload.serviceURL,
      siteURL: payload.siteURL
    }
    state.requestVars[payload.dashboardID] = {}
    state.siteVariables[payload.dashboardID] = {}
    state.handlebars[payload.dashboardID] = SiteHandlebars(state, payload.dashboardID)
    if (!Object.prototype.hasOwnProperty.call(state.requestQueue, payload.dashboardID)) {
      state.requestQueue[payload.dashboardID] = async.queue(requestHandler({
        state: state,
        dashboardID: payload.dashboardID,
        handlebars: state.handlebars[payload.dashboardID],
        siteid: payload.siteid,
        auth: state.dashboards[payload.dashboardID].auth,
        serviceURL: payload.serviceURL,
        siteURL: payload.siteURL
      }), 6)
    }
  },

  SET_DATES: function(state, payload) {
    if (!Object.prototype.hasOwnProperty.call(payload, 'dashboardID')) {
      throw new Error('Missing dashboard ID')
    }
    state.requestVars[payload.dashboardID].startdate = payload.start
    state.requestVars[payload.dashboardID].enddate = payload.end
    state.trigger += 1
  },
  SET_REQUEST_VARS: function(state, payload) {
    if (!Object.prototype.hasOwnProperty.call(payload, 'dashboardID')) {
      throw new Error('Missing dashboard ID')
    }
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'dashboardID') {
        if (value === undefined || value === null) {
          delete state.requestVars[payload.dashboardID][key]
        }
        else {
          state.requestVars[payload.dashboardID][key] = value
        }
      }
    })
    state.trigger += 1
  },
  SET_SITEVARS: function(state, payload) {
    if (!Object.prototype.hasOwnProperty.call(payload, 'dashboardID')) {
      throw new Error('Missing dashboard ID')
    }
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'dashboardID') {
        if (value === undefined || value === null) {
          delete state.siteVariables[payload.dashboardID][key]
        }
        else {
          state.siteVariables[payload.dashboardID][key] = value
        }
      }
    })
  }
}

const actions = {
  init: function({ commit, dispatch }, payload) {
    if (!Object.prototype.hasOwnProperty.call(payload, 'dashboardID')) {
      throw new Error('Missing dashboard ID')
    }
    commit('SET_DASHBOARD_SETTINGS', {
      dashboardID: payload.dashboardID,
      siteid: payload.siteid,
      auth: payload.auth,
      siteURL: payload.siteURL,
      serviceURL: Object.prototype.hasOwnProperty.call(payload, 'serviceURL') ? payload.serviceURL : `https://biin.api.aspedia.io`
    })
    return dispatch('loadSiteVariables', { dashboardID: payload.dashboardID })
  },
  request: function({ state }, payload) {
    if (!Object.prototype.hasOwnProperty.call(payload, 'dashboardID')) {
      throw new Error('Missing dashboard ID')
    }
    if (!Object.prototype.hasOwnProperty.call(state.requestQueue, payload.dashboardID)) {
      state.requestQueue[payload.dashboardID] = async.queue(requestHandler, 6)
    }
    return new Promise((resolve, reject) => {
      state.requestQueue[payload.dashboardID].push(payload.query, function(err, data) {
        if (err) {
          reject(err)
        }
        else {
          resolve(data)
        }
      })
    })
  },
  loadSiteVariables: function({ state, commit }, payload) {
    if (!Object.prototype.hasOwnProperty.call(payload, 'dashboardID')) {
      throw new Error('Missing dashboard ID')
    }
    if (!Object.prototype.hasOwnProperty.call(state.dashboards, payload.dashboardID)) {
      throw new Error(`Missing Configration for Dashboard: ${payload.dashboardID}`)
    }

    const siteVariablesQuery = `
      query {
        poschannels: variable(name: "pos_channels")
      }`

    const client = new GraphQLClient(`${state.dashboards[payload.dashboardID].siteURL}/graphql`, siteVariablesQuery)

    return state.dashboards[payload.dashboardID].auth()
      .then(jwt => client.query({ context: { headers: { 'Authorization': `Bearer ${jwt}` } } }))
      .then(result => {
        if (result.errors) {
          throw new Error('Variables request failed')
        }
        else {
          commit('SET_SITEVARS', {
            dashboardID: payload.dashboardID,
            poschannels: result.data.poschannels.pos_channels
          })
        }
      })
  }
}

const getters = {
  requestVars: (state) => (dashboardID) => {
    if (!Object.prototype.hasOwnProperty.call(state.requestVars, dashboardID)) {
      return {}
    }
    return state.requestVars[dashboardID]
  },

  siteid: (state) => (dashboardID) => {
    if (!Object.prototype.hasOwnProperty.call(state.dashboards, dashboardID)) {
      return 'unknown'
    }
    return state.dashboards[dashboardID].siteid
  },

  siteVariables: (state) => (dashboardID) => {
    if (!Object.prototype.hasOwnProperty.call(state.siteVariables, dashboardID)) {
      return {}
    }
    return state.siteVariables[dashboardID]
  },
  handlebars: (state) => (dashboardID) => {
    if (!Object.prototype.hasOwnProperty.call(state.handlebars, dashboardID)) {
      throw new Error(`Error accessing handlerbars engine for dashboard: ${dashboardID}`)
    }
    return state.handlebars[dashboardID]
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
