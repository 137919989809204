<template>
  <div
    class="report-dashboard-item-content"
    :style="rowstyles"
  >
    <div v-html="content" />
  </div>
</template>

<script>
export default {
  name: 'vue-dashboard-item-content',
  props: {
    itemConfig: {
      type: Object,
      required: true,
      default: () => ({
        enable: true,
        type: 'heading',
        content: 'No charts are defined'
      })
    }
  },
  computed: {
    rowstyles: function() {
      return !Object.prototype.hasOwnProperty.call(this.itemConfig, 'styles') ? '' : Object.entries(this.itemConfig.styles).map(([key, value]) => `${key}: ${value};`).join('; ')
    },
    content: function() {
      return this.itemConfig.content
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
