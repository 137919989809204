<template>
  <section class="form-group">
    <label
      :for="widgetid"
      class="form-group__label"
    >
      {{ config.label }}:
    </label>
    <swoggle
      :id="widgetid"
      v-model="widgetValue"
      class="form-group__input form-control form-group__input--swoggle"
    />
  </section>
</template>

<script>
import Swoggle from '@aspedia/vuejs-swoggle'

export default {
  name: 'vue-dashboard-filter-widget-swoggle',
  components: {
    Swoggle
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
    }
  },
  computed: {
    widgetid: function() {
      return `${this.config.value}--swoggle`
    },
    widgetValue: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
