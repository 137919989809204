import dayjs from 'dayjs'

// eslint-disable-next-line camelcase
import dayjs_plugin_utc from 'dayjs/plugin/utc'
// eslint-disable-next-line camelcase
import dayjs_plugin_timezone from 'dayjs/plugin/timezone'
// eslint-disable-next-line camelcase
import dayjs_plugin_quarterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.extend(dayjs_plugin_utc)
dayjs.extend(dayjs_plugin_timezone)
dayjs.extend(dayjs_plugin_quarterOfYear)

dayjs.tz.setDefault(dayjs.tz.guess())

export default {
  'today': {
    start: dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'yesterday': {
    start: dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'this week': {
    start: dayjs().startOf('week').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().endOf('week').format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last week': {
    start: dayjs().subtract(1, 'week').startOf('week').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'this month - to date': {
    start: dayjs().startOf('month').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last month - to Date': {
    start: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last 2 months': {
    start: dayjs().subtract(2, 'month').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last 3 months': {
    start: dayjs().subtract(3, 'month').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last 7 days': {
    start: dayjs().subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last 14 days': {
    start: dayjs().subtract(14, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last 21 days': {
    start: dayjs().subtract(21, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last 28 days': {
    start: dayjs().subtract(28, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'this calendar year - to date': {
    start: dayjs().startOf('year').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'last calendar year - to date': {
    start: dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'this quarter - to date': {
    start: dayjs().startOf('quarter').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
  },
  'this quarter last year - to date': {
    start: dayjs().startOf('quarter').subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ssZ'),
    end: dayjs().subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ssZ')
  }
}
