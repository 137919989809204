<template>
  <div
    class="vue-dashboard-item-echart"
    :style="rowstyles"
  >
    <loading-spinner v-if="!valid && !noData">
      <div slot="title">
        Loading
      </div>
    </loading-spinner>
    <v-chart
      v-if="valid"
      class="chart"
      :option="chartConfig"
    />
    <div
      v-if="noData"
      class="no-data"
    >
      No Data
    </div>
  </div>
</template>

<script>
import 'echarts'
import LoadingSpinner from '@aspedia/loading-spinner'
import VChart from 'vue-echarts'
import RequestMixin from './request-mixin'

const findFormatter = function(value) {
  const tooltipFormatters = {
    '@@currency': function(params) {
      let value = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(0)
      try {
        value = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(params.data)
      }
      catch (err) {
        // No-Op
      }
      return `<div class='tooltip'><label class='tooltip-name'>${params.name}</label><br/><div class='tooltip-value'>${value}</div></div>`
    }
  }

  const formatterName = Object.keys(tooltipFormatters).find(formatter => value.startsWith(formatter))
  if (formatterName) {
    return tooltipFormatters[formatterName]
  }
}

export default {
  name: 'vue-dashboard-item-echart',
  components: {
    VChart,
    LoadingSpinner
  },
  mixins: [
    RequestMixin
  ],
  props: {
    dashboardId: {
      type: String,
      required: true
    },
    itemConfig: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      myChart: null,
      resultData: null
    }
  },
  computed: {
    postQueryHandler: function() {
      return Object.prototype.hasOwnProperty.call(this.itemConfig, 'postQuery') ? this.handlebars.compile(this.itemConfig.postQuery) : null
    },
    chartConfigHandler: function() {
      return this.handlebars.compile(this.itemConfig.chartConfig)
    },
    handlebars: function() {
      return this.$store.getters['vuedashboard/handlebars'](this.dashboardId)
    },
    rowstyles: function() {
      return !Object.prototype.hasOwnProperty.call(this.itemConfig, 'styles') ? '' : Object.entries(this.itemConfig.styles).map(([key, value]) => `${key}: ${value};`).join('; ')
    },
    requestVars: function() {
      return this.valid ? this.$store.state.vuedashboard.requestVars[this.dashboardId] : {}
    },
    noData: function() {
      return this.resultData !== null && Object.keys(this.resultData).length === 0
    },
    valid: function() {
      return this.resultData !== null && Object.keys(this.resultData).length !== 0
    },
    chartConfig: function() {
      let config = null
      try {
        if (this.valid) {
          config = this.chartConfigHandler({ ...this.resultData, ...this.requestVars })
          config = JSON.parse(config, function(key, value) {
            let formatter = null
            if (typeof value === 'string' && (formatter = findFormatter(value))) {
              return formatter
            }
            if (typeof value === 'string' && value.startsWith('@@Function(') && value.endsWith(')')) {
              value = unescape(value.substring(12, value.length - 2))
              // eslint-disable-next-line no-eval
              return (0, eval)('(' + value + ')')
            }
            return value
          })
          return config
        }
        return {}
      }
      catch (err) {
        console.error(`Error generating chart configuration: ${err.message}`)
        console.error(config)
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .vue-dashboard-item-echart {
    min-height: 250px;
    height: 100%;
  }

</style>
