import GraphQLClient from '@aspedia/graphql_client'

const loginQuery = `
  query login($u: String!, $p: String!) {
    login(username: $u, password: $p)
    siteid: variable(name: "jwt_siteid")
    pubkey: authkey
    site_location: variable(name: "components_primary_name")
  }`

export default {
  methods: {
    loginRequest: function({ username, password, siteurl }) {
      const client = new GraphQLClient(`${siteurl}/graphql`, loginQuery)
      return client.query({ opName: 'login', variables: { u: username, p: password } })
        .then(result => {
          if (result.errors) {
            throw new Error('Login failed')
          }
          else {
            return {
              auth: result.data.login,
              siteid: result.data.siteid.jwt_siteid,
              pubkey: result.data.pubkey,
              site_location: result.data.site_location.components_primary_name
            }
          }
        })
    }
  }
}
