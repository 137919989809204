/* eslint-disable no-unused-vars */
import dates from './components/dates'

const currencyFormatter = function(params) {
  let value = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(0)
  try {
    value = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(params.data)
  }
  catch (err) {
    // No-Op
  }
  return `
    <div class='tooltip'>
      <div style='display: flex; flex-direction: row;'>
        <div style='background-color: ${params.color}; width: 8px; height: 8px; padding: 2px; margin: 2px;'>&nbsp;</div>
        ${params.seriesName}
      </div>
      <label class='tooltip-name'>${params.name}</label>
      <div class='tooltip-value'>${value}</div>
    </div>`
}

const posvsCartWineseriesBarchartSingle = {
  type: 'echarts',
  query: `
    from(bucket: "transactions")
      |> range(start: {{startdate}}, stop: {{enddate}})
      |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_series_ptype_channel_hourly"))
      |> filter(fn: (r) => (r["_field"] == "price"))
      |> filter(fn: (r) => (r["ttype"] == "pos" or r["ttype"] == "cart"))
      |> drop( columns: ["channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
      |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> yield(name: "sales")
  `,
  postQuery: `{
    "possales": {{{JSONstringify (extractData "series" "price" (filterData "ttype" "pos" sales))}}},
    "cartsales": {{{JSONstringify (extractData "series" "price" (filterData "ttype" "cart" sales))}}}
  }`,
  chartConfig: `{
    "title": {
      "text": "Point Of Sales Wine Sales By Series - Single Query"
    },
    "legend": {
      "data": [ "POS Sales", "Cart Sales" ]
    },
    "xAxis": {
      "data": {{{JSONstringify (keys possales)}}}
    },
    "yAxis": {},
    "series": [
      {
        "name": "POS Sales",
        "type": "bar",
        "data": {{{JSONstringify (values possales)}}}
      },
      {
        "name": "Cart Sales",
        "type": "bar",
        "data": {{{JSONstringify (values cartsales)}}}
      }
    ]
  }`
}

const posvsCartWineseriesBarchart = {
  type: 'echarts',
  query: `
    from(bucket: "transactions")
      |> range(start: {{startdate}}, stop: {{enddate}})
      |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_series_ptype_channel_hourly"))
      |> filter(fn: (r) => (r["_field"] == "price"))
      |> filter(fn: (r) => (r["ttype"] == "pos"))
      |> drop( columns: ["ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
      |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> yield(name: "possales")

    from(bucket: "transactions")
      |> range(start: {{startdate}}, stop: {{enddate}})
      |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_series_ptype_channel_hourly"))
      |> filter(fn: (r) => (r["_field"] == "price"))
      |> filter(fn: (r) => (r["ttype"] == "cart"))
      |> drop( columns: ["ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
      |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> yield(name: "cartsales")
  `,
  postQuery: `{
    "possales": {{{JSONstringify (normalizeSeries (extractData "series" "price" possales) (extractData "series" "price" cartsales))}}},
    "cartsales": {{{JSONstringify (normalizeSeries (extractData "series" "price" cartsales) (extractData "series" "price" possales))}}}
  }`,
  chartConfig: `{
    "title": {
      "text": "Point Of Sales Wine Sales By Series - Dual Query"
    },
    "tooltip": {},
    "legend": {
      "data": [ "POS Sales", "Cart Sales" ]
    },
    "xAxis": {
      "data": {{{JSONstringify (keys possales)}}}
    },
    "yAxis": {},
    "series": [
      {
        "name": "POS Sales",
        "type": "bar",
        "data": {{{JSONstringify (values possales)}}}
      },
      {
        "name": "Cart Sales",
        "type": "bar",
        "data": {{{JSONstringify (values cartsales)}}}
      }
    ]
  }`
}

const cartWineseriesBarchart = {
  type: 'echarts',
  query: `from(bucket: "transactions")
    |> range(start: {{startdate}}, stop: {{enddate}})
    |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_series_ptype_channel_hourly"))
    |> filter(fn: (r) => (r["_field"] == "price"))
    |> filter(fn: (r) => (r["ttype"] == "cart"))
    |> drop( columns: ["ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
    |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
    |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
    |> yield(name: "cartsales")
  `,
  postQuery: `{ "data": {{{JSONstringify (extractData "series" "price" cartsales)}}} }`,
  chartConfig: `{
    "title": {
      "text": "E-Commerce Wine Sales By Series"
    },
    "xAxis": {
      "data": {{{JSONstringify (keys data)}}}
    },
    "tooltip": {},
    "yAxis": {},
    "series": [
      {
        "name": "sales",
        "type": "bar",
        "data": {{{JSONstringify (values data)}}}
      }
    ]
  }`
}

const posChannelWineseriesBarchart = {
  type: 'echarts',
  query: `from(bucket: "transactions")
    |> range(start: {{startdate}}, stop: {{enddate}})
    |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_series_ptype_channel_hourly"))
    |> filter(fn: (r) => (r["_field"] == "price"))
    |> filter(fn: (r) => (r["ttype"] == "pos"))
    |> filter(fn: (r) => (r["channel"] == "{{channel}}"))
    |> drop( columns: ["ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
    |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
    |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
    |> yield(name: "channelsales")
  `,
  postQuery: `{ "data": {{{JSONstringify (extractData "series" "price" channelsales)}}} }`,
  chartConfig: `{
    "title": {
      "text": "POS {{channel__LABEL}} Wine Sales By Series"
    },
    "xAxis": {
      "data": {{{JSONstringify (keys data)}}}
    },
    "tooltip": {},
    "yAxis": {},
    "series": [
      {
        "name": "sales",
        "type": "bar",
        "data": {{{JSONstringify (values data)}}},
        "tooltip": {
          "formatter": "@@Function('${escape(currencyFormatter.toString())}')"
        }
      }
    ]
  }`
}

const posWineseriesBarchart = {
  type: 'echarts',
  query: `from(bucket: "transactions")
    |> range(start: {{startdate}}, stop: {{enddate}})
    |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_series_ptype_channel_hourly"))
    |> filter(fn: (r) => (r["_field"] == "price"))
    |> filter(fn: (r) => (r["ttype"] == "pos"))
    |> drop( columns: ["ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
    |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
    |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
    |> yield(name: "cartsales")
  `,
  postQuery: `{ "data": {{{JSONstringify (extractData "series" "price" cartsales)}}} }`,
  chartConfig: `{
    "title": {
      "text": "E-Commerce Wine Sales By Series"
    },
    "xAxis": {
      "data": {{{JSONstringify (keys data)}}}
    },
    "tooltip": {},
    "yAxis": {},
    "series": [
      {
        "name": "sales",
        "type": "bar",
        "data": {{{JSONstringify (values data)}}}
      }
    ]
  }`
}

const posChannelTotalSales = {
  type: 'datavalue',
  query: `from(bucket: "transactions")
    |> range(start: {{startdate}}, stop: {{enddate}})
    |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_ptype_by_channel_hourly"))
    |> filter(fn: (r) => (r["_field"] == "price"))
    |> filter(fn: (r) => (r["ttype"] == "pos"))
    |> filter(fn: (r) => (r["channel"] == "{{channel}}"))
    |> drop( columns: ["ptype", "ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
    |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
    |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
    |> yield(name: "totalsales")
  `,
  postQuery: `{ "value": {{extractRowData 0 "price" totalsales}} }`,
  content: `<div class='total-sales'><h4>{{channel__LABEL}} Channel Sales</h4>&nbsp; {{NumberFormatCurrency "en-AU" "AUD" value}}</div>`
}

const POSTotalSalesDataValue = {
  type: 'datavalue',
  query: `from(bucket: "transactions")
    |> range(start: {{startdate}}, stop: {{enddate}})
    |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_ptype_by_channel_hourly"))
    |> filter(fn: (r) => (r["_field"] == "price"))
    |> filter(fn: (r) => (r["ttype"] == "pos"))
    |> drop( columns: ["ptype", "ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
    |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
    |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
    |> yield(name: "totalsales")
  `,
  postQuery: `{ "value": {{extractRowData 0 "price" totalsales}} }`,
  content: `<div class='total-sales'><h4>POS Total Sales</h4>&nbsp; {{NumberFormatCurrency "en-AU" "AUD" value}}</div>`
}

const CartTotalSalesDataValue = {
  type: 'datavalue',
  query: `from(bucket: "transactions")
    |> range(start: {{startdate}}, stop: {{enddate}})
    |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_ptype_by_channel_hourly"))
    |> filter(fn: (r) => (r["_field"] == "price"))
    |> filter(fn: (r) => (r["ttype"] == "cart"))
    |> drop( columns: ["ptype", "ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
    |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
    |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
    |> yield(name: "totalsales")
  `,
  postQuery: `{ "value": {{extractRowData 0 "price" totalsales}} }`,
  content: `<div class='total-sales'><h4>E-Commerce Total Sales</h4>&nbsp; {{NumberFormatCurrency "en-AU" "AUD" value}}</div>`
}

const TotalSalesDataValue = {

  type: 'datavalue',
  query: `from(bucket: "transactions")
    |> range(start: {{startdate}}, stop: {{enddate}})
    |> filter(fn: (r) => (r["_measurement"] == "all_sales_by_ptype_by_channel_hourly"))
    |> filter(fn: (r) => (r["_field"] == "price"))
    |> drop( columns: ["ptype", "ttype", "channel", "channelname", "terminal", "terminalname", "_measurement", "_start", "_stop", "ptype"])
    |> aggregateWindow(every: 5y, fn: sum, createEmpty: false)
    |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
    |> yield(name: "totalsales")
  `,
  postQuery: `{ "value": {{extractRowData 0 "price" totalsales}} }`,
  content: `<div class='total-sales'><h4>Total Sales</h4>&nbsp; {{NumberFormatCurrency "en-AU" "AUD" value}}</div>`
}

const dashboards = {
  salesdashboard: {
    title: 'Sales Dashboard',
    enable: true,
    description: 'Report of sales for different Transaction Types',
    content: {
      above: '<div style="text-align: center;"><h1>Date Range: {{dateFormat "dddd, D MMM, YYYY" startdate}} - {{dateFormat "dddd, D MMM, YYYY" enddate}}</h1></div>',
      below: '--End--'
    },
    dates: dates,
    defaultDate: 'this month - to date',
    dashboardContent: [
      {
        enable: true,
        items: [
          {
            type: 'content',
            enable: true,
            content: '<h1>Sales Totals<h1>'
          }
        ]
      },
      {
        enable: true,
        items: [
          { enable: true, ...TotalSalesDataValue },
          { enable: true, ...POSTotalSalesDataValue },
          { enable: true, ...CartTotalSalesDataValue }

        ]
      },
      {
        enable: false,
        styles: {
          'width': '400px'
        },
        items: [
          {
            type: 'content',
            enable: true,
            content: '<h3>Charts</h3>'
          }
        ]
      },
      {
        enable: true,
        styles: {
          'min-height': '200px'
        },
        items: [
          { enable: true, ...posvsCartWineseriesBarchartSingle, styles: { 'min-width': '50%' } },
          { enable: true, ...posvsCartWineseriesBarchart, styles: { 'min-width': '50%' } },
          { enable: true, ...posWineseriesBarchart, styles: { 'min-width': '50%' } },
          { enable: true, ...cartWineseriesBarchart, styles: { 'min-width': '50%' } }

        ]
      }
    ]
  },
  posdashboard: {
    title: 'POS Dashboard',
    enable: true,
    description: 'Report of sales for Specific POS Channel/Terminal',
    additionalOptions: `[
      {
        "label": "POS Channel",
        "description": "Select the POS Channel to report on",
        "type": "select",
        "value": "channel",
        "default": "{{ extractValue (keys (poschannels)) 1 }}",
        "options": {{{ JSONstringify (poschannels) }}}
      },
      {
        "label": "POS Terminal",
        "description": "Select the Termioanl from the selected POS Channel",
        "type": "select",
        "value": "terminal",
        "default": "",
        "options": {{{ JSONstringify (poschannelterminals) }}}
      }
    ]`,
    dates: dates,
    defaultDate: 'this month - to date',
    content: {
      above: `<div style="text-align: center;">
      <!--
      <div>SiteID: {{ siteid }}</div>
      <div>
        POS Channels
        <pre>
          {{ JSONstringify (poschannels) }}
        </pre>
      </div>
      <div>
        Config
        <pre>
          {{ JSONstringify (config) }}
        </pre>
      </div>
      <div>
        POS Terminals
        <pre>
          {{ JSONstringify (posterminals) }}
        </pre>
      </div>
      <div>
        POS Channel Terminals
        <pre>
          {{ JSONstringify (poschannelterminals) }}
        </pre>
      </div>
      -->
      Channel: {{channel__LABEL}} -- Date Range: {{dateFormat "dddd, D MMM, YYYY" startdate}} - {{dateFormat "dddd, D MMM, YYYY" enddate}}</div>`,
      below: '--End--'
    },
    dashboardContent: [
      {
        enable: true,
        items: [
          {
            type: 'content',
            enable: true,
            content: '<h1>Sales Totals<h1>'
          }
        ]
      },
      {
        enable: true,
        items: [
          { enable: true, ...posChannelTotalSales }
        ]
      },
      {
        enable: true,
        styles: {
          'width': '400px'
        },
        items: [
          {
            type: 'content',
            enable: true,
            content: '<h3>Charts</h3>'
          }
        ]
      },
      {
        enable: true,
        styles: {
          'min-height': '200px'
        },
        items: [
          { enable: true, ...posChannelWineseriesBarchart, styles: { 'min-width': '100%', 'height': '600px' } }
        ]
      }
    ]
  }
}

export default dashboards
