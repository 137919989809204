<template>
  <div id="app">
    <loading-spinner v-if="loading">
      <div slot="title">
        Loading Site Auth
      </div>
      <div slot="message">
        Please wait while we load the site authorization
      </div>
    </loading-spinner>
    <div
      v-if="loggedIn && !loading"
      class="logout-button"
    >
      <a
        href="#"
        @click="logout"
      >
        Logout
      </a>
    </div>
    <login-form
      v-if="!loggedIn && !loading"
      :default-site-url="datastoreEndpoint"
      :default-username="username"
      :default-password="password"
      :default-endpoint="biinEndpoint"
      :default-config="dashboardConfig"
      @success="login"
    />
    <template v-if="loggedIn && !loading && dashboardConfigCValid">
      <div
        v-for="(dashboard, id) in confirmedDashboardConfig"
        :key="id"
      >
        <vue-dashboard-page
          v-if="!loading && dashboard.enable"
          :siteid="siteid"
          :auth="auth"
          :endpoint="confirmedBiinEndpoint"
          :dashboard-config="dashboard"
          :site-url="confirmedDatastoreEndpoint"
        />
      </div>
    </template>
  </div>
</template>

<script>

import LoadingSpinner from '@aspedia/loading-spinner'
import LoginForm from './components/login/login-form.vue'

export default {
  name: 'app',
  components: {
    LoginForm,
    LoadingSpinner
  },
  props: {
    username: {
      type: String,
      required: true,
      default: 'Developer'
    },
    password: {
      type: String,
      requried: true,
      default: ''
    },
    biinEndpoint: {
      type: String,
      required: false,
      default: ''
    },
    datastoreEndpoint: {
      type: String,
      required: false,
      default: ''
    },
    dashboardConfig: {
      type: Object,
      required: false,
      default: () => ({
        default: {
          title: 'No Reports',
          enable: true,
          description: 'No Reports configuration was provided',
          dashboardContent: [
            {
              enable: true,
              items: [
                {
                  type: 'content',
                  enable: true,
                  content: '<h1>Empty<h1>'
                }
              ]
            }
          ]
        }
      })
    }
  },
  data: function() {
    return {
      loading: true,
      auth: null,
      siteid: null,
      confirmedBiinEndpoint: null,
      confirmedDatastoreEndpoint: null,
      confirmedDashboardConfig: null
    }
  },
  computed: {
    loggedIn: function() {
      return this.auth !== null && this.siteid !== null
    },
    dashboardConfigCValid: function() {
      return this.confirmedDashboardConfig !== null
    }
  },
  mounted: function() {
    try {
      const loginDetails = JSON.parse(localStorage.getItem('loginDetails'))
      if (loginDetails.siteid && loginDetails.auth) {
        this.login(loginDetails)
      }
    }
    catch (err) {
      // No-Op
    }
    this.loading = false
  },
  methods: {
    logout: function() {
      this.siteid = null
      this.auth = null
      localStorage.removeItem('loginDetails')
      this.$store.commit('vuedashboard/RESET')
    },
    loadDashboardConfig: function(dashboardConfigURL) {
      if (dashboardConfigURL.length) {
        const url = `/proxy?url=${encodeURIComponent(dashboardConfigURL)}`
        return fetch(url, {
          method: 'GET',
          redirect: 'follow',
          cache: 'no-cache'
        })
          .then(resp => {
            if (resp.ok) {
              console.log(`Loading Dashboard config from : ${dashboardConfigURL}`)
              return resp.json()
            }
            else {
              return resp.text()
                .then(data => {
                  console.log(JSON.stringify(data, null, 2))
                  console.log(`HTTP Response: ${resp.status} - ${resp.statusText}`)
                  console.error('Error loading alternative dashboard config - reverting to included default')
                  return this.dashboardConfig
                })
            }
          })
          .catch(err => {
            console.error(`Error retreiving dashboard config: ${err.message}`)
            return this.dashboardConfig
          })
      }
      else {
        return Promise.resolve(this.dashboardConfig)
      }
    },
    login: function(loginDetails) {
      this.loading = true
      this.siteid = loginDetails.siteid
      this.auth = loginDetails.auth
      this.confirmedBiinEndpoint = loginDetails.biinEndpoint
      this.confirmedDatastoreEndpoint = loginDetails.datastoreEndpoint

      return this.loadDashboardConfig(loginDetails.dashboardConfig.trim())
        .then(config => {
          this.confirmedDashboardConfig = config
          this.loading = false
          localStorage.setItem('loginDetails', JSON.stringify(loginDetails))
        })
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px 10px;
  height: 100%;

  .logout-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.8rem 2.2rem;
  }
}
</style>
