<template>
  <div
    v-if="dashboardItemEnabled"
    class="vue-dashboard-item"
    :style="rowstyles"
  >
    <component
      :is="dashboardComponent"
      :dashboard-id="dashboardId"
      :item-config="itemConfig"
    />
  </div>
</template>

<script>

import VueDashboardItemContent from './dashboard-items/vue-dashboard-item-content.vue'
import VueDashboardItemEchart from './dashboard-items/vue-dashboard-item-echart.vue'
import VueDashboardItemDatavalue from './dashboard-items/vue-dashboard-item-datavalue.vue'
import VueDashboardItemEmpty from './dashboard-items/vue-dashboard-item-empty.vue'

export default {
  name: 'vue-dashboard-item',
  components: {
    VueDashboardItemContent,
    VueDashboardItemEchart,
    VueDashboardItemDatavalue,
    VueDashboardItemEmpty
  },
  props: {
    dashboardId: {
      type: String,
      required: true
    },
    itemConfig: {
      type: Object,
      required: true,
      default: () => ({
        enable: true,
        type: 'heading',
        content: 'No charts are defined'
      })
    }
  },
  data: function() {
    return {}
  },
  computed: {
    rowstyles: function() {
      return !Object.prototype.hasOwnProperty.call(this.itemConfig, 'styles') ? '' : Object.entries(this.itemConfig.styles).map(([key, value]) => `${key}: ${value};`).join('; ')
    },
    dashboardItemEnabled: function() {
      return Object.prototype.hasOwnProperty.call(this.itemConfig, 'enable') ? this.itemConfig.enable : true
    },
    dashboardComponent: function() {
      switch (this.itemConfig.type) {
      case 'content': return 'vue-dashboard-item-content'
      case 'datavalue': return 'vue-dashboard-item-datavalue'
      case 'echarts':
      case 'echart': return 'vue-dashboard-item-echart'
      case 'empty':
      default: return 'vue-dashboard-item-empty'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .vue-dashboard-item {
    min-width: 400px;
    min-height: 100%;
    flex: 1;
  }
</style>
