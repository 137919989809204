<template>
  <section class="form-group">
    <label
      :for="widgetid"
      class="form-group__label"
    >
      {{ config.label }}:
    </label>
    <select
      :id="widgetid"
      v-model="widgetValue"
      class="form-group__input form-control form-group__input--select"
    >
      <option
        v-for="opt in ConvertOptions"
        :key="opt.value"
        :value="opt.value"
      >
        {{ opt.label }}
      </option>
    </select>
  </section>
</template>

<script>
export default {
  name: 'vue-dashboard-filter-widget-select',
  props: {
    config: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: function() {
    return {
    }
  },
  computed: {
    widgetid: function() {
      return `${this.config.value}--select`
    },
    widgetValue: {
      get: function() {
        return this.value || this.config.default || ''
      },
      set: function(newValue) {
        this.$emit('input', newValue)
      }
    },
    ConvertOptions: function() {
      let options = this.config.options
      if (this.config.depends) {
        const dependantValue = this.$parent.additionalOptionsValues[this.config.depends]
        if (Object.prototype.hasOwnProperty.call(this.config.options, dependantValue)) {
          options = this.config.options[dependantValue]
        }
      }
      return Object.entries(options).map(([value, label]) => ({ label, value }))
    }
  }
  // watch: {
  //   value: function(newValue) {
  //     this.$set(this, 'widgetValue', newValue)
  //   },
  //   widgetValue: function(newValue) {
  //     this.$emit('input', newValue)
  //   }
  // }
}
</script>

<style lang="scss" scoped>

  .form-group {
    display: block;
    margin-right: 1em;
    margin-top: 0.25em;

    &__label {
      display: block;
      opacity: 0.7;
      font-weight: 400;
      min-width: 180px;
    }

  }

</style>
