import dayjs from 'dayjs'
import Handlebars from 'handlebars'
import uniq from 'lodash/uniq'

const parseBoolean = function(str, dflt) {
  if (typeof str === 'boolean') {
    return str
  }
  if (typeof str === 'string') {
    if (['y', 't', '1', 'yes', 'true', 'on'].includes(str.toLowerCase())) {
      return true
    }
    if (['n', 'f', '0', 'no', 'false', 'off'].includes(str.toLowerCase())) {
      return false
    }
  }
  if (typeof str === 'number') {
    return !!str
  }
  return dflt !== undefined ? dflt : false
}

export default function getDashboardHandleBars(state, dashboardID) {
  const myHandlebars = Handlebars.create()

  myHandlebars.registerHelper('NumberFormatCurrency', function(locale, currency, value) {
    if (value === undefined) {
      value = currency
      currency = locale
      locale = 'en-AU'
    }
    else if (currency === undefined) {
      value = locale
      locale = 'en-AU'
      currency = 'AUD'
    }
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value)
  })

  myHandlebars.registerHelper('defaultValue', function(value, dflt) {
    return value === undefined || value === null ? dflt : value
  })

  myHandlebars.registerHelper('JSONstringify', function(data) {
    return JSON.stringify(data, null, 2)
  })

  myHandlebars.registerHelper('JSONparse', function(str) {
    try {
      return JSON.parse(str)
    }
    catch (err) {
      return str
    }
  })

  myHandlebars.registerHelper('mangleString', function(str) {
    if (typeof str === 'string') {
      return str.replace(/\s+/g, '_')
    }
    return str
  })

  myHandlebars.registerHelper('Uppercase', function(str) {
    if (typeof str === 'string') {
      return str.toUpperCase()
    }
    return str
  })

  myHandlebars.registerHelper('Lowercase', function(str) {
    if (typeof str === 'string') {
      return str.toLowerCase()
    }
    return str
  })

  myHandlebars.registerHelper('parseBoolean', function(str, dflt) {
    return parseBoolean(str, dflt)
  })

  myHandlebars.registerHelper('dateFormat', function(format, dte) {
    return dayjs(new Date(dte)).format(format)
  })

  myHandlebars.registerHelper('keys', function(val) {
    if (typeof val === 'object') {
      return Object.keys(val)
    }
    return val
  })

  myHandlebars.registerHelper('values', function(val) {
    if (typeof val === 'object') {
      return Object.values(val)
    }
    return val
  })

  myHandlebars.registerHelper('extractRowData', function(rowno, field, data) {
    try {
      if (Array.isArray(data)) {
        return data[rowno][field]
      }
    }
    catch (err) {
      // No-Op
    }
    return data
  })

  myHandlebars.registerHelper('extractData', function(keyValue, valueValue, data) {
    if (Array.isArray(data)) {
      return data.reduce((acc, row) => ({ ...acc, [row[keyValue]]: row[valueValue] }), {})
    }
    return data
  })

  myHandlebars.registerHelper('normalizeSeries', function(series1, series2) {
    let keys = uniq([...Object.keys(series1), ...Object.keys(series2)])

    return keys.reduce((acc, key) => {
      return { ...acc, [key]: Object.prototype.hasOwnProperty.call(series1, key) ? series1[key] : 0 }
    }, {})
  })

  myHandlebars.registerHelper('filterData', function(field, value, data) {
    return data.filter(row => row[field] === value)
  })

  myHandlebars.registerHelper('config', function() {
    return state.dashboards[dashboardID]
  })

  myHandlebars.registerHelper('extractValue', function(arr, idx) {
    if (Array.isArray(arr)) {
      return arr[idx]
    }
    return ''
  })

  myHandlebars.registerHelper('poschannels', function() {
    return Object.entries(state.siteVariables[dashboardID].poschannels)
      .reduce((channels, [channelid, channelconfig]) => {
        return channelconfig.active !== undefined && !parseBoolean(channelconfig.active) ? channels : { ...channels, [channelid]: channelconfig.name }
      }, { '': 'Select Channel' })
  })

  myHandlebars.registerHelper('posterminals', function() {
    return Object.entries(state.siteVariables[dashboardID].poschannels)
      .reduce((terminals, [_channelid, channelconfig]) => {
        const channelTerminals = Object.entries(channelconfig.terminals).reduce((acc, [terminalid, terminalconfig]) => {
          return !parseBoolean(terminalconfig.active) ? acc : { ...acc, [terminalid]: terminalconfig.name }
        }, { '': 'Select Terminal' })
        return channelconfig.active !== undefined && !parseBoolean(channelconfig.active) ? terminals : { ...terminals, ...channelTerminals }
      }, { '': 'Select Terminal' })
  })

  myHandlebars.registerHelper('siteid', function() {
    return state.dashboards[dashboardID].siteid
  })

  myHandlebars.registerHelper('poschannelterminals', function() {
    const result = Object.entries(state.siteVariables[dashboardID].poschannels)
      .reduce((terminals, [channelid, channelconfig]) => {
        if (parseBoolean(channelconfig.active)) {
          terminals[channelid] = Object.entries(channelconfig.terminals)
            .reduce((acc, [terminalid, terminalconfig]) => {
              return terminalconfig.active !== undefined && !parseBoolean(terminalconfig.active) ? acc : { ...acc, [terminalid]: terminalconfig.name }
            }, { '': 'Select Terminal' })
        }
        return terminals
      }, { '': { '': 'Select Channel' } })

    return Object.entries(result).reduce((acc, [channel, terminals]) => {
      return channel === '' || Object.keys(terminals).length > 1 ? { ...acc, [channel]: terminals } : acc
    }, {})
  })

  return myHandlebars
}
