<template>
  <div
    class="dashboard-item-datavalue"
    :style="rowstyles"
  >
    <loading-spinner v-if="!valid && !noData">
      <div slot="title">
        Loading
      </div>
    </loading-spinner>
    <div
      v-if="valid"
      v-html="content"
    />
    <div
      v-if="noData"
      class="no-data"
    >
      No Data
    </div>
  </div>
</template>

<script>

import RequestMixin from './request-mixin'
import LoadingSpinner from '@aspedia/loading-spinner'

export default {
  name: 'vue-dashboard-item-datavalue',
  components: {
    LoadingSpinner
  },
  mixins: [
    RequestMixin
  ],
  props: {
    dashboardId: {
      type: String,
      required: true
    },
    itemConfig: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      resultData: null
    }
  },
  computed: {
    postQueryHandler: function() {
      return Object.prototype.hasOwnProperty.call(this.itemConfig, 'postQuery') ? this.handlebars.compile(this.itemConfig.postQuery) : null
    },
    contentHandler: function() {
      return this.handlebars.compile(this.itemConfig.content)
    },
    handlebars: function() {
      return this.$store.getters['vuedashboard/handlebars'](this.dashboardId)
    },
    noData: function() {
      return this.resultData !== null && Object.keys(this.resultData).length === 0
    },
    valid: function() {
      return this.resultData !== null && Object.keys(this.resultData).length !== 0
    },
    requestVars: function() {
      return this.valid ? this.$store.state.vuedashboard.requestVars[this.dashboardId] : {}
    },
    rowstyles: function() {
      return !Object.prototype.hasOwnProperty.call(this.itemConfig, 'styles') ? '' : Object.entries(this.itemConfig.styles).map(([key, value]) => `${key}: ${value};`).join('; ')
    },
    content: function() {
      return this.valid ? this.contentHandler({ ...this.resultData, ...this.requestVars }) : 'No data'
    }
  }
}
</script>

<style lang="scss" scoped>
  .dashboard-item-datavalue {
    min-height: 100%;
    text-align: center;
    border: 1px solid black;
    padding: 2rem 1.2rem;
    font-size: 2em;
    flex-grow: 1;
    box-sizing: border-box;
  }
</style>
