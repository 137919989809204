<template>
  <div class="login-form-wrapper">
    <div class="login-form">
      <section class="filters-form">
        <section class="form-group form-group--siteurl">
          <label
            for="siteurl"
            class="form-group__label"
          >
            Site URL
          </label>
          <input
            id="siteurl"
            v-model="siteurl"
            :disabled="loading"
            type="text"
            class="form-group__input form-control form-group__input--text"
          >
        </section>

        <section class="form-group form-group--endpoint">
          <label
            for="binn-endpoint"
            class="form-group__label"
          >
            Binn Microservice Endpoint
          </label>
          <input
            id="binn-endpoint"
            v-model="endpoint"
            :disabled="loading"
            class="form-group__input form-control form-group__input--text"
            type="text"
          >
        </section>

        <section class="form-group form-group--username">
          <label
            for="username"
            class="form-group__label"
          >
            Username
          </label>
          <input
            id="username"
            v-model="username"
            :disabled="loading"
            class="form-group__input form-control form-group__input--text"
            type="text"
          >
        </section>

        <section class="form-group form-group--password">
          <label
            for="password"
            class="form-group__label"
          >
            Password
          </label>
          <input
            id="password"
            v-model="password"
            :disabled="loading"
            class="form-group__input form-control form-group__input--password"
            type="password"
          >
        </section>

        <section class="form-group form-group--input-textarea report-config">
          <label
            for="report-config"
            class="form-group__label"
          >
            Report Config URL
          </label>
          <input
            id="report-config"
            v-model="dashboardConfig"
            :disabled="loading"
            class="form-group__input form-control form-group__input--text"
            type="text"
            placeholder="Leave blank to use the default config"
          >
        </section>

        <section
          v-if="errorMsg"
          class="error-message"
        >
          {{ errorMsg }}
        </section>

        <section class="form-group form-group__button login-button">
          <div
            v-if="loading"
            class="login-processing"
          >
            <loading-spinner>
              <div slot="title">
                Processing Login
              </div>
              <div slot="message">
                Please wait while we process the login request...
              </div>
            </loading-spinner>
          </div>
          <div v-if="!loading">
            <button @click="login">
              login
            </button>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import loginMixin from './login-mixin'
import LoadingSpinner from '@aspedia/loading-spinner'

export default {
  name: 'vue-dashboard-app-login',
  components: {
    LoadingSpinner
  },
  mixins: [
    loginMixin
  ],
  props: {
    defaultSiteUrl: {
      type: String,
      required: true
    },
    defaultUsername: {
      type: String,
      required: false,
      default: ''
    },
    defaultPassword: {
      type: String,
      required: false,
      default: ''
    },
    defaultEndpoint: {
      type: String,
      required: false,
      default: ''
    },
    defaultConfig: {
      type: [ Object, String ],
      required: false,
      default: ''
    }
  },
  data: function() {
    return {
      siteurl: this.defaultSiteUrl,
      username: this.defaultUsername,
      password: this.defaultPassword,
      endpoint: this.defaultEndpoint,
      dashboardConfig: '',
      loading: false,
      errorMsg: null
    }
  },
  methods: {
    login: function() {
      this.loading = true
      this.errorMsg = null
      this.loginRequest({
        siteurl: this.siteurl,
        username: this.username,
        password: this.password
      })
        .then(loginResults => {
          this.$emit('success', {
            ...loginResults,
            datastoreEndpoint: this.siteurl,
            binnEndpoint: this.endpoint,
            dashboardConfig: this.dashboardConfig
          })
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.errorMsg = `Login failed: ${err.message}`
          setTimeout(() => {
            this.errorMsg = null
          }, 3500)
        })
    }
  }
}
</script>

<style lang="scss" scoped>

  .login-form-wrapper {
    margin-top: 20%;

    .error-message {
      font-size: 2.2em;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 2.2rem 0;
      color: red;
      font-weight: bold;
    }

    .login-form {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .filters-form {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5em;

        .login-button {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
        }

        .form-group {
          display: flex;
          flex-direction: row;
          padding: 10px 0;
          margin-right: 1em;
          margin-top: 0.25em;
          .login-button {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
          }

          &__label {
            display: flex;
            align-items: center;
            min-width: 220px;
            font-weight: 400;
            min-width: fit-content;
            padding: 0 0.8rem 0 0;
          }

          &__input {
            font-size: 16px;
            cursor: pointer;
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding: 8px 20px 8px 8px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 12px;
            background: transparent;
            border: 1px solid #808080;
            box-shadow: 0 0.1em 0.3em rgba(0,0,0,.05);
            outline: 0;
          }

          &__input--select.form-control {
            font-size: 14px;
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding: 8px 20px 8px 8px!important;
            border-radius: 4px;
            background: transparent;
            border: 1px solid #808080;
            box-shadow: 0 0.1em 0.3em rgba(0,0,0,.05);
            outline: 0;
            cursor: pointer;

            & > option[disabled] {
              opacity: 0.7;
            }
          }

          &__button {
            button {
            border-radius: 12px;
            padding: 0.8rem 2.2rem;
            font-size: 1.2em;
            }
          }
        }

        .form-group.report-config {
          flex-direction: column;
          width: 800px;

          label {
            padding-bottom: 0.8rem;
          }
        }
      }
    }
  }
</style>
