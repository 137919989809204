import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'

import VueDashboardPage from './index'
import ExampleReports from './example-reports'

Vue.config.productionTip = false
Vue.use(Vuex)

const store = new Vuex.Store({
  state: () => ({}),
  actions: {},
  mutations: {},
  getters: {}
})

Vue.use(VueDashboardPage, { store: store })

const Username = 'Developer'
const Password = ''
const datastore = 'https://crm.bleasdale.website.vincreative.com'
const biinEndpoint = 'https://biin.api.aspedia.io'

new Vue({
  store,
  render: h => h(App, {
    props: {
      username: Username,
      password: Password,
      datastoreEndpoint: datastore,
      biinEndpoint: biinEndpoint,
      dashboardConfig: ExampleReports
    }
  })
}).$mount('#app')
